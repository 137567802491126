.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(000, 000, 000, 0.4);
  z-index: 4;
}

.Popup {
  position: absolute;
  max-width: 653px;
  background-color: #ffffff;
  box-shadow: 0px 10px 25px rgba(37, 36, 36, 0.1);
  border-radius: 20px;
  z-index: 5;
  padding: 32px 37px 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .Popup {
    max-width: 340px;
    padding: 15px 20px 25px;
  }
}
